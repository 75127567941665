import React from "react";

import NewsArticle from "../../../components/news-article/news-article.component";

import { LATEST_RATE_SHEET } from "../../../data/latest-rate-sheet.data";

const Article = () => (
  <NewsArticle>
    <h1 className="page-title">Changes to our mortgage products</h1>
    <p>With effect from Tuesday 4 October 2022 we will be making the following changes to our range:</p>
    <p>
      <strong>Summary of changes</strong>
    </p>
    <p>
      We have removed from sale our Two Year Tracker products within our UK Buy To Let Purchase, Remortgage and Existing
      Customer Borrowing More ranges until further notice.
    </p>
    <p>There are no changes to any of our other products or ranges.</p>
    <p>An updated mortgage rate sheet will be published on Tuesday 4 October 2022 to reflect these changes.</p>
    <p>
      <a title="This PDF opens in a new window" target="_blank" href={LATEST_RATE_SHEET.href}>
        Link to our latest rate sheet
      </a>
    </p>
  </NewsArticle>
);

export default Article;
